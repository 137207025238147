




























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";
@Component
export default class UserDeactivate extends Vue {
  @Prop()
  public propData;
  @Prop()
  public activeOrDeactiveIndex;
  @Prop()
  public usersList: any;
  @Prop()
  userStatusChangingIndex;
  public statusChangingIndex = null;
  public personalInfo: any = null;
  public borrowerInfo: any = [];
  public borrowerData: any = null;
  public propDataValue: any = null;
  public isPrimaryBorrowerError: any = null;

  public async deactivateUser(borrower, i) {
    try {
      if (borrower.primaryBorrower) {
        let response = await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            activate: borrower.userActive,
            loanTxnId: borrower.loanTxnId,
            userType: "Borrower",
            userId: borrower.userId,
            activationType: "multiple",
            isPrimaryBorrower: borrower.primaryBorrower
          });
        
        let message = response.data.userActive
          ? "Borrower has been activated"
          : "Borrower has been deactivated";
        this.$snotify.success(message);
        if(!response.data.userActive){
        this.$modal.hide('activeOrDeactiveModal');
        this.$emit('callMountFunction', true)
        }

      } else {
        this.isPrimaryBorrowerError = null;
        let response = await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            activate: borrower.userActive,
            userType: "Borrower",
            userId: borrower.userId,
            activationType: "multiple"
          });
        let message = response.data.userActive
          ? "Borrower has been activated"
          : "Borrower has been deactivated";
        this.$snotify.success(message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async openActiveOrDeactiveModal(data) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getAllUsers",
        {
          loanTxnId:
            data.user.userType == "Broker" ||
            data.userType == "MLO" ||
            data.userType == "Admin Staff" ||
            data.userType == "Wemlo Processor"
              ? data.userId
              : data.loanTxnId,
          userType: data.user.userType
        });
      this.borrowerInfo = response.data;
      this.$modal.show("activeOrDeactiveModal");
    } catch (error) {}
  }
  openModalOrClose(loanTxnId) {
    this.propDataValue = loanTxnId;
    this.openActiveOrDeactiveModal(loanTxnId);
  }

  mounted() {}
}
