import { createDecorator } from 'vue-class-component';
import _ from 'lodash';

export default createDecorator((options, key) => {
  const method = options.methods[key];

  options.methods[key] = _.debounce(async function debounceMethod(...args) {
    return method.apply(this, args);
  }, 600);
});
