


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import moment from "moment";
import { borrowerInfo } from "@/models/borrowerInfo.model";
import UpdateUserInfo from "@/views/UpdateUserInfo.vue";
import { log } from "util";
import { createNamespacedHelpers } from "vuex";
import UpdateUserStatus from "@/views/UpdateUserStatus.vue";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import saveAs from "save-as";
import _ from "lodash";
import Debounce from '@/decorators/Debounce';


@Component({ components: { UpdateUserInfo, UpdateUserStatus } })
export default class ListBrokerComponent extends mixins(CommonMixin) {
  public dotLoader = false;
  public borrowers = [];
  public loanStatus = [];
  public basis = "";
  public selectedReason = 'AC030';
  public reasons = [
    {
      value: 'AC030',
      label: 'Applications Approved but Not Accepted'
    },
    {
      value: 'AC040',
      label: 'Applications Denied'
    },
    {
      value: 'AC050',
      label: 'Applications Withdrawn'
    },
    {
      value: 'AC060',
      label: 'File Closed for Incompleteness'
    },
    {
      value: 'AC062',
      label: 'Pre-Approval Requests Denied'
    },
    {
      value: 'AC064',
      label: 'Pre-Approval Requests Approved but not Accepted'
    }
  ]
  public currentBorrowerIndex: number = 0;
  public processors = [];
  public companyNames = [];
  public isModalOpened = false;
  public searchCompanyName = "All";
  public borrowerInfo = [];
  public personalInfo: any = [];
  public selectedLoanTxnId = null;
  public searchLoanString = "";
  public borrowerContacts = "";
  public loanHistory = null;
  public borrowerLoader = false;
  public selectedProcessorId = null;
  public selectedPreviousProcessorId = null;
  public loanTxnId = null;
  public roleId = null;
  public roleName = null;
  public totalBorrowerCount = 0;
  public userInfo: any = {};
  public currentLengthOnScroll = 0;
  public activeOrDeactiveIndex: any = false;
  public skip = 0;
  public searchByType = null;
  public searchByAction = null;
  public descending = true;
  public borrowersLoanTxnId = null;
  public showLoaderScroll = false;
  public brokerProcessor: any = [];
  public type = null;
  public status = null;
  public statusId = null;
  public borrowerCountLoader = false;
  public availableUsersToReassignLoan: Array<{ userId: string, fullName: string }> = [];
  public assignedMLO: string = null;

  public updatedLoan = {
    loanTxnId: null,
    enumerationType: null
  };

  public get userType() {
    return this.$store.state.sessionObject.type;
  }

  /*********************************************************************************************************************************
   *                     fetch all borrower's details                                                                              *
   *                     Manager Name -> superadmin/ borrower.manager                                                              *
   *                     Function Name -> getBorrowers                                                                             *
   *                                                                                                                               *
   *********************************************************************************************************************************/
  public async getTotalBorrowerCount() {
    this.borrowerCountLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "borrower/getTotalBorrowerCount",
        {
          userId: this.$userId,
          userType: this.$userType,
          fieldName: this.searchByType,
          searchString:
            this.searchLoanString != "" && this.searchLoanString != null
              ? this.searchLoanString
              : null,
          basis: this.basis,
          action: this.searchByAction
        });
      this.companyNames = response.data.companyNames;
      this.totalBorrowerCount = response.data.totalBorrowerCount;
    } catch (error) {
      console.log(error);
    }
    this.borrowerCountLoader = false;
  }

  @Debounce
  public async getBorrowers(mountCall: boolean = false) {
    try {
      const limit = 50;
      this.skip = mountCall ? 0 : this.skip + limit;
      if (mountCall) {
        this.borrowerLoader = true;
        await this.getTotalBorrowerCount();
      }
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getBorrowers",
        {
          type: this.userType,
          fieldName: this.searchByType,
          options: {
            skip: this.skip,
            limit,
          },
          searchString:
            this.searchLoanString != "" && this.searchLoanString != null
              ? this.searchLoanString
              : null,
          desc: this.descending,
          basis: this.basis,
          status: this.status,
          action: this.searchByAction,
        });
      if (mountCall)
        this.borrowers = response.data.borrowers;
      else 
        this.borrowers = this.borrowers.concat(response.data.borrowers);
      this.currentLengthOnScroll = this.borrowers.length;
    } catch (error) {
      console.error(error);
    } finally {
      this.borrowerLoader = false;
      this.showLoaderScroll = false;
    }
  }

  public async showMoreDataOnScroll() {
    if (!this.showLoaderScroll && this.currentLengthOnScroll < this.totalBorrowerCount) {
      this.showLoaderScroll = true;
      await this.getBorrowers(false);
    }
  }

  public getClosingDate(data) {
    let closingDate = null;
    if (data.closingDate != null) {
      closingDate = moment(data.closingDate).format("MMMM D, YYYY");
      return closingDate != null ? closingDate : "-----";
    } else {
      return (closingDate = "-------");
    }
  }

  /**
   * get Processor List Against Role
   */

  public async getProcessorListAgainstRole(processorId, role, loanTxnId) {
    try {
      this.selectedPreviousProcessorId = processorId;
      this.roleId = role.id;
      this.loanTxnId = loanTxnId;
      this.roleName = role.name;
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getProcessorListAgainstRole",
        {
          params: {
            roleId: this.roleId,
            loanTxnId: this.loanTxnId
          }
        }
      );
      this.processors = response.data.processors;
      this.$modal.show("changeAssignedProcessor");
    } catch (error) {
      console.log(error);
    }
  }

  public async changeAssignedProcessor() {
    if (
      this.selectedProcessorId &&
      this.selectedPreviousProcessorId &&
      this.selectedProcessorId != this.selectedPreviousProcessorId
    ) {
      this.$store.commit('SET_WEMLO_LOADER', true);
      try {
        let res = await Axios.get(
          BASE_API_URL + "superAdmin/getProcessorReports",
          {
            params: {
              processorId: this.selectedPreviousProcessorId
            }
          }
        );
        this.$store.commit('SET_WEMLO_LOADER', false);
        if (res.data.reportExist) {
          try {
            await this.$dialog.confirm("There are reports for the previous processor, you still want to proceed ?");
            await this.editProcessor();
          } catch (error) {
            console.error(error);
          }
        } else {
          await this.editProcessor();
        }
      } catch (error) {
        console.error(error);
        this.$store.commit('SET_WEMLO_LOADER', false);
      }
    } else {
      if (!this.selectedProcessorId)
        this.$snotify.error("Please select Processor ");
      if (
        this.selectedProcessorId &&
        this.selectedProcessorId == this.selectedPreviousProcessorId
      )
        this.$snotify.error("Please select other Processor");
      return;
    }
  }

  public async editProcessor() {
    this.$store.commit('SET_WEMLO_LOADER', true);
    try {
      const response = await Axios.post(
        BASE_API_URL + "wemloprocessor/changeAssignedProcessor",
        {
          selectedProcessorId: this.selectedProcessorId,
          selectedPreviousProcessorId: this.selectedPreviousProcessorId,
          loanTxnId: this.loanTxnId,
          roleId: this.roleId,
          ...(this.roleName === "Loan Setup" ? { isProcessorOfRecord: true } : {}),
        });
      this.$modal.hide("changeAssignedProcessor");
      this.$snotify.success(response.data.message);
      this.selectedProcessorId = null;
      await this.getBorrowers(true);
    } catch (error) {
      console.error(error);
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false);
    }
  }

  public async getLoanType() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/getLoanType",
        {
          loanType: "loanstatus"
        });
      this.loanStatus = response.data.loanStatus;

      /**
       *  add uw response second in filter
       */
      let data = {
        name: "UW Response 2+",
        id: "uw"
      };
      this.loanStatus.push(data);
    } catch (error) {
      console.log(error);
    }
  }
  public async assignProcessor(brokerId, loanTxnId, loanStatus) {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/processorAssignedBySuperAdmin",
        {
          loanTxnId: loanTxnId,
          brokerId: brokerId,
          loanStatus: loanStatus
        });
      this.$store.state.wemloLoader = false;
      if (response.data.assigned) {
        this.$snotify.success(response.data.message);
      } else this.$snotify.error(response.data.message);
      this.getBorrowers(true);
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public hideModalProcessor() {
    this.$modal.hide("juniorProcessorName");
  }

  //For Update Borrower's E-mail, Phone, Name
  showPersonalDetailModal(modalName, borrower) {
    let data = {
      loanTxnId: borrower.loanTxnId,
      userType: "Borrower",
      modalName: modalName
    };

    this.personalInfo = [{ ...borrower, ...data }];
    this.$refs.updateUserStatus["openModalOrClose"](this.personalInfo);
  }

  public async updateBorrowerInfo(updatedData) {
    await this.getBorrowers(true);
  }

  public async undoLoanTask(taskHistoryId) {
    try {
      await this.$dialog.confirm("Are you sure, you want to undo this task?", {
        okText: "Confirm",
        cancelText: "Cancel"
      });
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/undoLoanTask",
        {
          loanTxnId: this.selectedLoanTxnId,
          taskHistoryId: taskHistoryId
        });
      this.$modal.hide("processNameModel");
      this.$store.state.wemloLoader = false;
      this.$snotify[response.data.status == 200 ? "success" : "error"](
        response.data.message
      );
      if (response.data.status == 200) this.getBorrowers(true);
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public async showContactDetails(loanTxnId) {
    this.$router.push({
      path: this.$getCurrentUserTypePath("borrower-contact"),
      query: { loanTxnId: loanTxnId }
    });
  }

  public closeUpdateLoanStatusModal() {
    this.selectedLoanTxnId = null;
    this.$modal.hide("updateLoanStatusModal");
  }
  //get loanHistory
  public async showLoanHistory(loanTxnId) {
    try {
      let response = await Axios.get(BASE_API_URL + "common/getLoanHistory", {
        params: {
          loanTxnId: loanTxnId
        }
      });
      this.loanHistory = response.data;
      this.$modal.show("processNameModel");
      this.selectedLoanTxnId = loanTxnId;
    } catch (error) {
      console.log(error);
    }
  }

  public showArchivedLoanDocument(loanTxnId) {
    this.$router.push({
      path: "/super-admin/borrower-detail",
      query: { loanTxnId: loanTxnId }
    });
  }

  public async openActiveOrDeactiveModal(borrower, i) {
    this.currentBorrowerIndex = i;
    this.$modal.show('activeOrDeactiveAccount')
  }

  public cancelModal() {
    this.$modal.hide('activeOrDeactiveAccount')
    this.$snotify.info("No changes");
  }

  public async submitModal() {
    this.$modal.hide('activeOrDeactiveAccount')

    if (this.borrowers[this.currentBorrowerIndex].borrowerLength === 1) {
      let borrower = this.borrowers[this.currentBorrowerIndex];
      let loanTxnId = borrower.loanTxnId;
      let response = await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            loanTxnId: loanTxnId,
            activate: !borrower.user.userActive,
            userType: borrower.user.userType,
            reason: this.selectedReason,
          }
      );
      let message = response.data.userActive
          ? "User has been activated"
          : "User has been deactivated";
      this.$snotify.success(message);

      /**
       * Refresh the list
       */
      await this.getBorrowers(true)
    } else {
      await this.handleForMultipleBorrower(this.borrowers[this.currentBorrowerIndex]);
    }
  }

  public async handleForMultipleBorrower(borrower) {
    try {
      this.userInfo = JSON.parse(JSON.stringify(borrower));
      this.borrowersLoanTxnId = this.userInfo;
      this.$refs.updateUserInfo["openModalOrClose"](this.userInfo);
    } catch (error) {
      console.log(error);
    }
  }

  public async openSelectInactiveReasonModal(currentBorrowerIndex) {
    this.currentBorrowerIndex = currentBorrowerIndex;
    this.$modal.show('selectInactiveReason');
  }

  public async cancelSelectInactiveReasonModal() {
    this.$modal.hide('selectInactiveReason');
  }

  public async submitSelectInactiveReasonModal() {
    this.$modal.hide('selectInactiveReason');

    try {
      let borrower = this.borrowers[this.currentBorrowerIndex];

      await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            loanTxnId: borrower.loanTxnId,
            activate: false,
            userType: borrower.user.userType,
            reason: this.selectedReason,
          }
      );
    
      this.$snotify.success("Reason set successfully");
    } catch (e) {
      this.$snotify.error("Something went wrong! Please try after sometime.");
    } finally {
      /**
       * Refresh list
       */
      await this.getBorrowers(true);
    }
  }

  getReasonLabel(value) {
    const reason = this.reasons.find(reason => reason.value === value);

    return _.get(reason, 'label');
  }

  public async downloadUploadedFile(loanTxnId) {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "common/downloadUploadedFile",
        { loanTxnId: loanTxnId },
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, loanTxnId + `.zip`);
      } else {
        this.$snotify.clear();
        this.$snotify.error("No document uploaded yet!");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public getValueOnChange() {
    this.searchLoanString = null;
    this.searchByAction = null;

    if (this.searchByType == null) {
      this.getBorrowers(true);
    }
  }
  public openModelForDelete(loanTxnId) {
    this.loanTxnId = loanTxnId;
    this.$modal.show("deleteLeadConfirmation");
  }

  public async deleteLoan() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "loan/deleteLoan",
        { loanTxnId: this.loanTxnId });
      this.$snotify.success(response.data.message);
      this.$modal.hide("deleteLeadConfirmation");
      await this.getTotalBorrowerCount();
      this.getBorrowers(true);
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async getBrokerAssignedProcessors(loanTxnId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getBrokerAssignedProcessors",
        {
          loanTxnId: loanTxnId
        });
      this.brokerProcessor = response.data.roleAllocation;
    } catch (error) {
      console.log(error);
    }
  }

  public setFilterValues(type, status, statusId) {
    this.searchByType = "statusId";
    this.searchByAction = "active";
    if (this.loanStatus.length > 0) {
      this.loanStatus.forEach(element => {
        if (element.id == this.statusId) {
          this.searchLoanString = this.statusId;
        }
      });
    }

    if (type) {
      type = type == "Date" ? "Today" : type;
      this.basis = type;
    }
  }

  public async showProcessorsDetails(i) {
    if (!this.borrowers[i].wemloProcessing) {
      await this.getBrokerAssignedProcessors(this.borrowers[i].loanTxnId);
      this.$modal.show("showProcessorsDetails");
    }
  }

  canChangeEnumerationType(loan) {

    /**
     * The rule to change the enumeration type is
     * If the enumeration type is mismo, nobody can change it back to fannieMae
     * If the loan is already submitted and not moved to lead the user cannot change the type
     * unless they are Processor or Super Admin
     */

    if (_.get(loan, 'loanInformation.enumerationType') === 'mismo') {
      return false;
    }

    if (['Wemlo Processor', 'Super Admin'].includes(this.$userType)) {
      return true;
    }

    if (_.get(loan, 'submissionStepId') === 'submission' && !_.get(loan, 'moveToLead')) {
      return false;
    }

    return true;
  }

  async updateEnumerationType(loanTxnId, enumerationType) {
    try {
      await Axios.post(BASE_API_URL + 'los/updateEnumerationType', {
        loanTxnId,
        enumerationType,
      });

      this.$snotify.success("Type updated successfully");

      this.openAfterChangingTypeModal(loanTxnId, enumerationType);

    } catch (error) {
      this.$snotify.error("Something went wrong! Please try after sometime.");
    }
  }

  openAfterChangingTypeModal(loanTxnId, enumerationType) {
    this.updatedLoan = {
      loanTxnId,
      enumerationType
    };

    this.$modal.show('afterChangingType');
  }

  closeAfterChangingTypeModal() {
    this.$modal.hide('afterChangingType');

    this.updatedLoan = {
      loanTxnId: null,
      enumerationType: null
    };
  }
  /**
   * show modal with available mlos and brokers to assign loan
   */
  public async showAssignMloModal(borrower) {
    this.loanTxnId = borrower.loanTxnId;
    this.assignedMLO = borrower.addedBy;
    let response = await Axios.get(BASE_API_URL + "broker/getMlosAndBrokerForLoanAssign",
      {
        params: {
        loanTxnId: this.loanTxnId
      }
    });
    this.availableUsersToReassignLoan = response.data.availableUsers;
    this.$modal.show("changeAssignedMLO");
    }

  public async editAssignedMlo() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/assignBrokerOrMloToLoan",
      {
          assignedMlo: this.assignedMLO,
          loanTxnId: this.loanTxnId,
        });
      this.$snotify.success(response.data.message);
      this.closeMloAssignModal();
      this.getBorrowers(true);
    } catch (error) {
        this.closeMloAssignModal();
    }
    this.$store.state.wemloLoader = false;
  }

  public closeMloAssignModal() {
    this.assignedMLO = null;
    this.loanTxnId = null;
    this.availableUsersToReassignLoan = [];
    this.$modal.hide('changeAssignedMLO');
  }

  async mounted() {
    await this.getLoanType();
    this.type = this.$route.query.type;
    this.status = this.$route.query.status;
    this.statusId = this.$route.query.statusId;
    if (this.status || this.statusId) {
      this.setFilterValues(this.type, this.status, this.statusId);
    }
    this.getBorrowers(true);
    // this.getTotalBorrowerCount();

  }
}
